<template>
    <div class="error-500">
        <div class="error-500__text">
            <h1>
                <span class="line">This Ylander has to go back!</span>
                <span class="line">We’re working on it.</span>
            </h1>
            <p>
                You can return to our
                <router-link to="/">homepage</router-link>
                or try again later<span class="hideit"> in a few minutes</span>.
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: '',
};
</script>

<style scoped lang="scss">
.error-500 {
    display: flex;
    justify-content: center;
    align-items: flex-end;

    background: #509aa8 url('~Images/error-500.jpg') center no-repeat;
    background-size: cover;
    height: 100vh;

    @media (max-width: $xl-down) {
        background-image: url('~Images/error-500-xl.jpg');
    }

    @media (max-width: $lg-down) {
        background-image: url('~Images/error-500-lg.jpg');
    }

    @media (max-width: $sm-down) {
        background-image: url('~Images/error-500-sm.jpg');
    }
}

.error-500__text {
    font-size: 1rem;
    margin-bottom: em(125);
    text-align: center;
    color: #fff;

    h1 {
        margin: 0 0 1em;
        font-size: em(32);
        letter-spacing: 0.05em;
    }

    p {
        margin: 0;
        font-size: em(24);
    }

    a {
        color: $yellow-main;
        text-decoration: underline;
    }

    @media (max-width: $xl-down) {
        margin-bottom: em(55);

        h1 {
            font-size: em(28);
            margin-bottom: em(20, 28);
        }

        p {
            font-size: em(21);
        }
    }

    @media (max-width: $lg-down) {
        max-width: 70%;
        margin-bottom: em(135);

        h1 {
            font-size: em(30);
            margin-bottom: 1em;

            .line {
                display: block;
            }
        }

        p {
            font-size: em(22);

            .hideit {
                display: none;
            }
        }
    }

    @media (max-width: $xs-down) {
        max-width: 95%;
        margin-bottom: 20%;

        h1 {
            font-size: em(19);
            margin-bottom: 1em;
        }

        p {
            font-size: em(16);
        }
    }
}
</style>
